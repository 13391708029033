import Pepperoni from "../assets/pepperoni.jpg";
import Margherita from "../assets/margherita.jpg";
import chefSpecial from "../assets/chefSpecial.jpg";
import Vegan from "../assets/vegan.jpg";
import Pineapple from "../assets/pineapple.jpg";
import Expensive from "../assets/expensive.jpg";

export const MenuList = [
  {
    name: "Pepperoni Pizza",
    image: Pepperoni,
    price:  15.99,
  },
  {
    name: "Margherita Pizza",
    image: Margherita,
    price: 11.99,
  },
  {
    name: "Chefs Special Pizza",
    image: chefSpecial,
    price: 39.53,
  },
  {
    name: "Vegan Pizza",
    image: Vegan,
    price: 17.99,
  },
  {
    name: "Pineapple Pizza",
    image: Pineapple,
    price: 14.99,
  },
  {
    name: "Very Expensive Pizza",
    image: Expensive,
    price: 19.99,
  },
];