import React from 'react'
import MultiplePizzas from '../assets/multiplePizzas.jpeg'
import '../styles/About.css'

function About() {
  return (
    <div className='about'>
      <div 
        className="aboutTop" 
        style={{backgroundImage: `url(${MultiplePizzas})`}}>
      </div>
      
      <div className="aboutBottom">
        <h1>ABOUT US</h1>
        <p>Welcome to our pizza shop, where we serve up the perfect slice every time! Using the freshest ingredients and handcrafted dough, we bring you a variety of pizzas that cater to all tastes—from classic Margherita to bold and unique flavors. Whether you're dining in or taking out, our goal is to deliver not just a meal, but an experience of comfort and flavor. Grab a slice and enjoy the passion we put into every pizza!</p>
      </div>
    </div>
  )
}

export default About